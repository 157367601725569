import React from 'react';
import { Box, VStack, HStack, Link, IconButton, useColorMode, useToast, Flex, Text, useBreakpointValue } from '@chakra-ui/react';
import { FaHome, FaUtensils, FaShoppingCart, FaSun, FaMoon, FaCog, FaListAlt, FaTable, FaSignOutAlt } from 'react-icons/fa';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logoutUser, clearAuthState } from '../authSlice'; // Update the path as needed
import { FaChartBar } from 'react-icons/fa';


const ChefSidebar = () => {
    const [isMinimized, setIsMinimized] = React.useState(true);
    const { colorMode, toggleColorMode } = useColorMode();
    const dispatch = useDispatch();
    const history = useHistory();
    const toast = useToast();
    const { role } = useSelector((state) => state.auth);
    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleLogout = async () => {
        try {
            await dispatch(logoutUser()).unwrap();
            dispatch(clearAuthState());
            localStorage.removeItem('token');
            localStorage.removeItem('role');
            localStorage.removeItem('employeeId');
            history.push('/');
            toast({
                title: "Logged out successfully",
                status: "success",
                duration: 3000,
                isClosable: true,
            });
        } catch (error) {
            toast({
                title: "Logout failed",
                description: error.message || "An error occurred during logout",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const links = [
        { path: '/table-list', icon: FaTable, label: 'Tables', roles: ['WAITER', 'ADMIN', 'RECEPTION'] },
        { path: '/orders', icon: FaShoppingCart, label: 'Orders', roles: ['WAITER', 'ADMIN', 'RECEPTION'] },
        { path: '/all-orders', icon: FaListAlt, label: 'All Orders', roles: ['ADMIN', 'RECEPTION'] },
        { path: '/chef-orders', icon: FaUtensils, label: 'Chef Orders', roles: ['CHEF', 'ADMIN', 'RECEPTION'] },
        { path: '/settings', icon: FaCog, label: 'Settings', roles: ['ADMIN'] },
        { path: '/report', icon: FaChartBar, label: 'Reports', roles: ['ADMIN', 'RECEPTION'] },
        { onClick: handleLogout, icon: FaSignOutAlt, label: 'Logout', roles: ['CHEF', 'WAITER', 'ADMIN', 'RECEPTION'] },
    ];

    const filteredLinks = links.filter(link => link.roles.includes(role));

    const SidebarContent = (
        <VStack align="stretch" spacing={4} width="full">
            {filteredLinks.map((link) => (
                <Link
                    as={RouterLink}
                    to={link.path}
                    key={link.label}
                    display="flex"
                    alignItems="center"
                    p={3}
                    borderRadius="md"
                    _hover={{ bg: 'whiteAlpha.200' }}
                    onClick={link.onClick}
                    justifyContent={isMinimized && !isMobile ? "center" : "flex-start"}
                >
                    <Box as={link.icon} fontSize="20px" />
                    {(!isMinimized || isMobile) && <Text ml={3}>{link.label}</Text>}
                </Link>
            ))}
        </VStack>
    );

    if (isMobile) {
        return (
            <Box
                position="fixed"
                bottom={0}
                left={0}
                right={0}
                bgGradient="linear(to-b, purple.600, cyan.600)"
                zIndex={1000}
            >
                <HStack justifyContent="space-around" p={2}>
                    {filteredLinks.map((link) => (
                        <IconButton
                            key={link.label}
                            icon={<Box as={link.icon} />}
                            aria-label={link.label}
                            variant="ghost"
                            color="white"
                            onClick={link.onClick || (() => history.push(link.path))}
                        />
                    ))}
                    <IconButton
                        icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
                        onClick={toggleColorMode}
                        aria-label="Toggle Color Mode"
                        variant="ghost"
                        color="white"
                    />
                </HStack>
            </Box>
        );
    }

    return (
        <Box
            position="fixed"
            left={0}
            top={0}
            bottom={0}
            w={isMinimized ? "60px" : "250px"}
            bgGradient="linear(to-b, purple.600, cyan.600)"
            color="white"
            p={5}
            overflowY="auto"
            transition="width 0.3s"
            onMouseEnter={() => setIsMinimized(false)}
            onMouseLeave={() => setIsMinimized(true)}
            zIndex={1000}
        >
            {!isMinimized && <Text fontSize="2xl" fontWeight="bold" mb={8}>Hotel Madhuban</Text>}
            {SidebarContent}
            <Box mt="auto" pt={8} display="flex" justifyContent={isMinimized ? "center" : "flex-start"}>
                <IconButton
                    icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
                    onClick={toggleColorMode}
                    aria-label="Toggle Color Mode"
                    variant="ghost"
                    color="white"
                />
            </Box>
        </Box>
    );
};

export default ChefSidebar;