// src/pages/Dashboard.js
import React from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Route, Switch } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
import Page1 from './Page1';
import Page2 from './Page2';

const Dashboard = () => {
  return (
    <Flex minH="100vh">
      <Sidebar />
      <Box flex="1" p={8} bg="white" _dark={{ bg: 'gray.700' }}>
        <Switch>
          <Route path="/dashboard/page1">
            <Page1 />
          </Route>
          <Route path="/dashboard/page2">
            <Page2 />
          </Route>
        </Switch>
      </Box>
    </Flex>
  );
};

export default Dashboard;
