import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Switch,
  NumberInput,
  NumberInputField,
  Spinner,
  Alert,
  AlertIcon,
  Grid,
  GridItem,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
} from '@chakra-ui/react';
import { apiCall } from './apiUtils';

const MenuItemConfig = ({ token }) => {
  const [menuItems, setMenuItems] = useState([]);
  const [formData, setFormData] = useState({
    name: '',
    categoryName: '',
    subCategory: '',
    cuisineName: '',
    description: '',
    dietInfo: '',
    availability: true,
    price: 0,
    isVariable: false,
  });
  const [editingMenuItemId, setEditingMenuItemId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchMenuItems();
  }, []);

  useEffect(() => {
    if (editingMenuItemId) {
      const menuItem = menuItems.find(item => item.id === editingMenuItemId);
      if (menuItem) {
        setFormData(menuItem);
      }
    } else {
      resetFormData();
    }
  }, [editingMenuItemId]);

  const fetchMenuItems = async () => {
    try {
      setLoading(true);
      const data = await apiCall('/menu-item/getAll', 'GET', token);
      setMenuItems(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch menu items');
      console.error('Error fetching menu items:', err);
    } finally {
      setLoading(false);
    }
  };

  const resetFormData = () => {
    setFormData({
      name: '',
      categoryName: '',
      subCategory: '',
      cuisineName: '',
      description: '',
      dietInfo: '',
      availability: true,
      price: 0,
      isVariable: false,
    });
  };

  const handleCreate = async () => {
    try {
      await apiCall('/menu-item/create', 'POST', token, formData);
      resetFormData();
      fetchMenuItems();
      onClose();
      toast({
        title: 'Menu item created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error creating menu item',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = async () => {
    try {
      await apiCall(`/menu-item/edit?id=${editingMenuItemId}`, 'PUT', token, formData);
      setEditingMenuItemId(null);
      fetchMenuItems();
      onClose();
      toast({
        title: 'Menu item updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating menu item',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiCall(`/menu-item/delete?id=${id}`, 'DELETE', token);
      fetchMenuItems();
      toast({
        title: 'Menu item deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting menu item',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    if (editingMenuItemId) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Alert status="error"><AlertIcon />{error}</Alert>;

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <Button onClick={onOpen} width="100%" mb={4}>
          {isOpen ? 'Close Form' : 'Add New Menu Item'}
        </Button>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Category</Th>
              <Th>Price</Th>
              <Th>Availability</Th>
              <Th>Variable Pricing</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {menuItems.map((item) => (
              <Tr key={item.id}>
                <Td>{item.name}</Td>
                <Td>{item.categoryName}</Td>
                <Td>{item.price}</Td>
                <Td>{item.availability ? 'Yes' : 'No'}</Td>
                <Td>{item.isVariable ? 'Yes' : 'No'}</Td>
                <Td>
                  <HStack>
                    <Button size="sm" colorScheme="blue" onClick={() => { setEditingMenuItemId(item.id); onOpen(); }}>Edit</Button>
                    <Button size="sm" colorScheme="red" onClick={() => handleDelete(item.id)}>Delete</Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingMenuItemId ? 'Edit Menu Item' : 'Add New Menu Item'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <Grid templateColumns="repeat(2, 1fr)" gap={4}>
                <GridItem>
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input
                      value={formData.name}
                      onChange={(e) => handleChange('name', e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Category Name</FormLabel>
                    <Input
                      value={formData.categoryName}
                      onChange={(e) => handleChange('categoryName', e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Sub Category</FormLabel>
                    <Input
                      value={formData.subCategory}
                      onChange={(e) => handleChange('subCategory', e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Cuisine Name</FormLabel>
                    <Input
                      value={formData.cuisineName}
                      onChange={(e) => handleChange('cuisineName', e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem colSpan={2}>
                  <FormControl>
                    <FormLabel>Description</FormLabel>
                    <Textarea
                      value={formData.description}
                      onChange={(e) => handleChange('description', e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Diet Info</FormLabel>
                    <Input
                      value={formData.dietInfo}
                      onChange={(e) => handleChange('dietInfo', e.target.value)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl>
                    <FormLabel>Price</FormLabel>
                    <NumberInput
                      value={formData.price}
                      onChange={(valueString) => handleChange('price', parseFloat(valueString))}
                    >
                      <NumberInputField />
                    </NumberInput>
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Availability</FormLabel>
                    <Switch
                      isChecked={formData.availability}
                      onChange={(e) => handleChange('availability', e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
                <GridItem>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel mb="0">Variable Pricing</FormLabel>
                    <Switch
                      isChecked={formData.isVariable}
                      onChange={(e) => handleChange('isVariable', e.target.checked)}
                    />
                  </FormControl>
                </GridItem>
              </Grid>
              <Button mt={4} colorScheme="blue" onClick={handleSubmit}>
                {editingMenuItemId ? 'Update Menu Item' : 'Create Menu Item'}
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MenuItemConfig;