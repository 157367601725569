// import React, { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { useHistory } from 'react-router-dom';
// import { loginUser } from '../authSlice';
// import { FaEye, FaEyeSlash, FaMoon, FaSun } from 'react-icons/fa';
// import {
//   Box,
//   Button,
//   Input,
//   FormControl,
//   FormLabel,
//   VStack,
//   Heading,
//   useColorMode,
//   HStack,
//   Text,
//   IconButton,
//   Flex,
//   InputGroup,
//   InputRightElement,
//   useToast,
// } from '@chakra-ui/react';

// const Login = () => {
//   const [username, setUsername] = useState('');
//   const [password, setPassword] = useState('');
//   const [showPassword, setShowPassword] = useState(false);
//   const dispatch = useDispatch();
//   const history = useHistory();
//   const { colorMode, toggleColorMode } = useColorMode();
//   const formBackground = colorMode === 'light' ? 'white' : 'gray.700';
//   const formTextColor = colorMode === 'light' ? 'gray.800' : 'white';
//   const toast = useToast();

//   const { isLoading, isError, errorMessage } = useSelector((state) => state.auth);

//   const handleLogin = (e) => {
//     e.preventDefault();
//     dispatch(loginUser({ username, password }))
//       .unwrap()
//       .then(({ role }) => {
//         toast({
//           title: "Login Successful",
//           description: `Welcome, ${username}!`,
//           status: "success",
//           duration: 3000,
//           isClosable: true,
//         });
//         // Navigate based on role
//         if (role === 'CHEF') {
//           history.push('/chef-orders');
//         } else {
//           history.push('/table-list');
//         }
//       })
//       .catch((error) => {
//         toast({
//           title: "Login Failed",
//           description: error.message || "Invalid username or password",
//           status: "error",
//           duration: 5000,
//           isClosable: true,
//         });
//       });
//   };

//   const togglePasswordVisibility = () => {
//     setShowPassword(!showPassword);
//   };

//   return (
//     <Flex minH="100vh" align="center" justify="center" bg={colorMode === 'light' ? '#f0f4f8' : '#2d3748'}>
//       <VStack
//         spacing={4}
//         p={8}
//         bg={formBackground}
//         boxShadow="lg"
//         borderRadius="lg"
//         w="full"
//         maxW="md"
//         textColor={formTextColor}
//       >
//         <HStack justifyContent="space-between" w="full">
//           <Heading fontSize="2xl">Login</Heading>
//           <IconButton
//             icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
//             onClick={toggleColorMode}
//             aria-label="Toggle Color Mode"
//           />
//         </HStack>
//         <FormControl>
//           <FormLabel>Username</FormLabel>
//           <Input
//             value={username}
//             onChange={(e) => setUsername(e.target.value)}
//             placeholder="Enter your username"
//             bg="gray.100"
//             _focus={{ bg: 'white' }}
//           />
//         </FormControl>
//         <FormControl>
//           <FormLabel>Password</FormLabel>
//           <InputGroup>
//             <Input
//               type={showPassword ? 'text' : 'password'}
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               placeholder="Enter your password"
//               bg="gray.100"
//               _focus={{ bg: 'white' }}
//             />
//             <InputRightElement>
//               <IconButton
//                 icon={showPassword ? <FaEyeSlash /> : <FaEye />}
//                 onClick={togglePasswordVisibility}
//                 aria-label="Toggle Password Visibility"
//                 variant="ghost"
//               />
//             </InputRightElement>
//           </InputGroup>
//         </FormControl>
//         {isError && (
//           <Text color="red.500" fontSize="sm">
//             {errorMessage}
//           </Text>
//         )}
//         <Button onClick={handleLogin} colorScheme="teal" w="full" size="lg" isLoading={isLoading}>
//           Login
//         </Button>
//       </VStack>
//     </Flex>
//   );
// };

// export default Login;

import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { loginUser } from '../authSlice';
import { FaEye, FaEyeSlash, FaMoon, FaSun, FaHotel } from 'react-icons/fa';
import {
  Box,
  Button,
  Input,
  FormControl,
  FormLabel,
  VStack,
  Heading,
  useColorMode,
  HStack,
  Text,
  IconButton,
  Flex,
  InputGroup,
  InputRightElement,
  useToast,
  Container,
} from '@chakra-ui/react';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const { colorMode, toggleColorMode } = useColorMode();
  const toast = useToast();

  const { isLoading, isError, errorMessage } = useSelector((state) => state.auth);

  const handleLogin = (e) => {
    e.preventDefault();
    dispatch(loginUser({ username, password }))
      .unwrap()
      .then(({ role }) => {
        toast({
          title: "Login Successful",
          description: `Welcome, ${username}!`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        if (role === 'CHEF') {
          history.push('/chef-orders');
        } else {
          history.push('/table-list');
        }
      })
      .catch((error) => {
        toast({
          title: "Login Failed",
          description: error.message || "Invalid username or password",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  return (
    <Flex 
      minH="100vh" 
      align="center" 
      justify="center" 
      bgGradient="linear(to-b, purple.600, cyan.600)"
    >
      <Container maxW="lg" py={12} px={6}>
        <VStack spacing={8} align="stretch">
          <VStack spacing={2} align="center">
            <FaHotel size={50} color="white" />
            <Heading fontSize="4xl" fontWeight="extrabold" color="white" letterSpacing="tight">
              Hotel Madhuban
            </Heading>
            <Text fontSize="lg" color="white" fontWeight="medium">
              Welcome back! Please sign in to your account.
            </Text>
          </VStack>
          <Box
            bg={colorMode === 'light' ? 'white' : 'gray.700'}
            p={8}
            borderRadius="xl"
            boxShadow="2xl"
            w="full"
          >
            <VStack spacing={4}>
              <FormControl>
                <FormLabel fontWeight="medium">Username</FormLabel>
                <Input
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  placeholder="Enter your username"
                  size="lg"
                  bg={colorMode === 'light' ? 'gray.100' : 'gray.600'}
                  border="none"
                  _focus={{ bg: colorMode === 'light' ? 'white' : 'gray.500', boxShadow: 'outline' }}
                />
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="medium">Password</FormLabel>
                <InputGroup size="lg">
                  <Input
                    type={showPassword ? 'text' : 'password'}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter your password"
                    bg={colorMode === 'light' ? 'gray.100' : 'gray.600'}
                    border="none"
                    _focus={{ bg: colorMode === 'light' ? 'white' : 'gray.500', boxShadow: 'outline' }}
                  />
                  <InputRightElement>
                    <IconButton
                      icon={showPassword ? <FaEyeSlash /> : <FaEye />}
                      onClick={togglePasswordVisibility}
                      variant="ghost"
                      aria-label={showPassword ? "Hide password" : "Show password"}
                    />
                  </InputRightElement>
                </InputGroup>
              </FormControl>
              {isError && (
                <Text color="red.500" fontSize="sm" fontWeight="medium">
                  {errorMessage}
                </Text>
              )}
              <Button
                onClick={handleLogin}
                colorScheme="purple"
                size="lg"
                width="full"
                isLoading={isLoading}
                fontWeight="bold"
                _hover={{ bg: 'cyan.500' }}
                transition="all 0.3s"
              >
                Sign In
              </Button>
            </VStack>
          </Box>
        </VStack>
        <HStack justify="center" mt={4}>
          <Text color="white" fontWeight="medium">Prefer a different theme?</Text>
          <IconButton
            icon={colorMode === 'light' ? <FaMoon /> : <FaSun />}
            onClick={toggleColorMode}
            variant="ghost"
            aria-label="Toggle color mode"
            color="white"
            _hover={{ bg: 'whiteAlpha.200' }}
          />
        </HStack>
      </Container>
    </Flex>
  );
};

export default Login;