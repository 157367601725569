import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Thead, Tbody, Tr, Th, Td, Spinner, Box, Text, Button, Input, IconButton } from '@chakra-ui/react';
import { useSelector } from 'react-redux';
import { FaEdit, FaSave } from 'react-icons/fa';

const PricingConfig = () => {
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editIndex, setEditIndex] = useState(null);
  const [multiplier, setMultiplier] = useState(null);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    fetchPricingData();
  }, []);

  const fetchPricingData = async () => {
    try {
      const response = await axios.get(
        'http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/area-config/getAll',
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      setPricingData(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching pricing data:', error);
      setLoading(false);
    }
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setMultiplier(pricingData[index].multiplier);
  };

  const handleSave = async (id) => {
    try {
      const response = await axios.put(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/area-config/edit?id=${id}`,
        { multiplier },
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      if (response.data === 1) {
        const updatedData = [...pricingData];
        updatedData[editIndex].multiplier = multiplier;
        setPricingData(updatedData);
        setEditIndex(null);
      } else {
        console.error('Error saving multiplier');
      }
    } catch (error) {
      console.error('Error saving multiplier:', error);
    }
  };

  if (loading) {
    return <Spinner />;
  }

  return (
    <Box>
      <Text fontSize="xl" mb={4}>Pricing Configuration</Text>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Area</Th>
            <Th>AC</Th>
            <Th>Multiplier</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {pricingData.map((item, index) => (
            <Tr key={item.id}>
              <Td>{item.area}</Td>
              <Td>{item.ac ? 'Yes' : 'No'}</Td>
              <Td>
                {editIndex === index ? (
                  <Input
                    type="number"
                    value={multiplier}
                    onChange={(e) => setMultiplier(parseFloat(e.target.value))}
                    width="100px"
                  />
                ) : (
                  item.multiplier
                )}
              </Td>
              <Td>
                {editIndex === index ? (
                  <IconButton
                    icon={<FaSave />}
                    onClick={() => handleSave(item.id)}
                    colorScheme="green"
                    aria-label="Save"
                  />
                ) : (
                  <IconButton
                    icon={<FaEdit />}
                    onClick={() => handleEdit(index)}
                    colorScheme="blue"
                    aria-label="Edit"
                  />
                )}
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};

export default PricingConfig;