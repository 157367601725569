import React from 'react';
import { Box, Flex, Tabs, TabList, TabPanels, Tab, TabPanel, Heading } from '@chakra-ui/react';
import DishesCategory from './Configs/DishesCategory';
import CuisineConfig from './Configs/CuisineConfig';
import MenuConfig from './Configs/MenuConfig';
import MenuItemConfig from './Configs/MenuItemConfig';
import AreaConfig from './Configs/AreaConfig';
import TableConfig from './Configs/TableConfig';
import TableDetails from './Configs/TableDetails';
import StaffManagement from './Configs/StaffManagement';
import { useSelector } from 'react-redux';
import Sidebar from '../components/Sidebar';  // Adjust the import path as necessary
import PricingConfig from './Configs/PricingConfig';

const SettingsPage = () => {
    const token = useSelector((state) => state.auth.token);
    const role = useSelector((state) => state.auth.role);

    return (
        <Flex minHeight="100vh">
            <Sidebar />
            <Box
                flex="1"
                //bg={colorMode === 'dark' ? 'gray.900' : 'gray.50'}
                ml={{ base: 0, md: '250px' }}
                transition="all 0.3s ease-in-out"
                pb={{ base: '60px', md: 0 }}
            >
                <Heading mb={5}>Settings</Heading>
                <Tabs>
                    <TabList>
                        {/* <Tab>Dishes Category</Tab>
                <Tab>Cuisine</Tab> */}
                        <Tab>Menu</Tab>
                        <Tab>Menu Items</Tab>
                        {/* <Tab>Area</Tab>
                        <Tab>Table Config</Tab>
                        <Tab>Table Details</Tab> */}
                        <Tab>Staff</Tab>
                        <Tab>Pricing</Tab>
                    </TabList>

                    <TabPanels>
                        {/* <TabPanel>
                <DishesCategory token={token} />
              </TabPanel>
              <TabPanel>
                <CuisineConfig token={token} />
              </TabPanel> */}
                        <TabPanel>
                            <MenuConfig token={token} />
                        </TabPanel>
                        <TabPanel>
                            <MenuItemConfig token={token} />
                        </TabPanel>
                        {/* <TabPanel>
                            <AreaConfig token={token} />
                        </TabPanel>
                        <TabPanel>
                            <TableConfig token={token} />
                        </TabPanel>
                        <TabPanel>
                            <TableDetails token={token} />
                        </TabPanel> */}
                        <TabPanel>
                            <StaffManagement token={token} />
                        </TabPanel>
                        <TabPanel>
                            <PricingConfig token={token} />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Box>
        </Flex>
    );
};

export default SettingsPage;