import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Flex,
  Text,
  VStack,
  SimpleGrid,
  useColorMode,
  Select,
  Input,
  IconButton,
  useBreakpointValue,
  useDisclosure,
  Spinner,
  Alert,
  AlertIcon,
  AlertTitle,
  HStack,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Button,
  Tooltip,
  useToast, // Add this import
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { FaCalendarAlt, FaTags, FaTrash } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip as RechartsTooltip, Legend, ResponsiveContainer } from 'recharts';
import Sidebar from '../components/Sidebar';

const MotionBox = motion(Box);

const Report = () => {
  const [activeTab, setActiveTab] = useState('waiter');
  const [startDate, setStartDate] = useState(new Date().toISOString().split('T')[0]);
  const [endDate, setEndDate] = useState(new Date().toISOString().split('T')[0]);
  const [singleDate, setSingleDate] = useState(new Date().toISOString().split('T')[0]);
  const token = useSelector((state) => state.auth.token);
  const [waiterPerformance, setWaiterPerformance] = useState([]);
  const [topDishes, setTopDishes] = useState([]);
  const [orders, setOrders] = useState([]);
  const toast = useToast(); // Add this line to create the toast function

  const history = useHistory();
  const { colorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const displayType = useBreakpointValue({ base: 'drawer', md: 'inline' });

  useEffect(() => {
    if (activeTab === 'waiter') {
      fetchWaiterPerformance();
    } else if (activeTab === 'items') {
      fetchTopDishes();
    } else if (activeTab === 'daybook') {
      fetchOrders();
    }
  }, [activeTab, startDate, endDate, singleDate]);

  const fetchWaiterPerformance = async () => {
    try {
      const response = await axios.get(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/orderItemLog/getForRange?start=${startDate}&end=${endDate}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      const performanceData = processWaiterPerformance(response.data);
      setWaiterPerformance(performanceData);
    } catch (error) {
      console.error('Error fetching waiter performance:', error);
    }
  };

  const fetchTopDishes = async () => {
    try {
      const response = await axios.get(
        `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/orderItemLog/getForRange?start=${startDate}&end=${endDate}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      const topDishesData = processTopDishes(response.data);
      setTopDishes(topDishesData);
    } catch (error) {
      console.error('Error fetching top dishes:', error);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        `https://api.hotelmadhuban.co.in:443/waiter/order/getDatewise?start=${singleDate}&end=${singleDate}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  };

  const processWaiterPerformance = (data) => {
    const waiterStats = {};
    data.forEach(item => {
      if (!waiterStats[item.staffId]) {
        waiterStats[item.staffId] = { 
          name: item.staffName,
          orderValue: 0, 
          numberOfOrders: 0 
        };
      }
      waiterStats[item.staffId].orderValue += item.count;
      waiterStats[item.staffId].numberOfOrders += 1;
    });

    return Object.values(waiterStats)
      .sort((a, b) => b.orderValue - a.orderValue);
  };

  const processTopDishes = (data) => {
    const dishStats = {};
    data.forEach(item => {
      if (!dishStats[item.itemName]) {
        dishStats[item.itemName] = 0;
      }
      dishStats[item.itemName] += item.count;
    });

    const totalCount = Object.values(dishStats).reduce((a, b) => a + b, 0);
    return Object.entries(dishStats)
      .map(([name, count]) => ({
        name,
        percentage: (count / totalCount) * 100
      }))
      .sort((a, b) => b.percentage - a.percentage)
      .slice(0, 10);
  };

  const handleDelete = async () => {
    try {
      // First, fetch the orders for the selected date
      const ordersResponse = await axios.get(
        `https://api.hotelmadhuban.co.in/waiter/order/getDatewise?start=${singleDate}&end=${singleDate}`,
        {
          headers: { 'Authorization': `Bearer ${token}` }
        }
      );

      // Filter orders with set numbers greater than 1 and group them by set number
      const ordersBySet = ordersResponse.data.reduce((acc, order) => {
        if (order.setNumber !== null && order.setNumber > 1) {
          if (!acc[order.setNumber]) {
            acc[order.setNumber] = [];
          }
          acc[order.setNumber].push(order);
        }
        return acc;
      }, {});

      const setNumbers = Object.keys(ordersBySet);

      if (setNumbers.length === 0) {
        toast({
          title: "No Orders to Delete",
          description: "No orders found with set numbers greater than 1.",
          status: "info",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // Delete orders for each set number greater than 1
      for (const setNumber of setNumbers) {
        const deleteResponse = await axios.delete(
          `http://ec2-54-253-7-206.ap-southeast-2.compute.amazonaws.com:8081/waiter/order/deleteBySet`,
          {
            params: {
              setNumber: setNumber,
              date: singleDate
            },
            headers: { 'Authorization': `Bearer ${token}` }
          }
        );

        if (deleteResponse.data !== 'Objects Deleted!') {
          throw new Error(`Failed to delete orders for set number ${setNumber}`);
        }
      }

      toast({
        title: "Success",
        description: `Orders from ${setNumbers.length} set(s) have been deleted.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      // Refresh the orders after deletion
      fetchOrders();

    } catch (error) {
      console.error('Error deleting orders:', error);
      toast({
        title: "Error",
        description: "Failed to delete some or all orders. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderBarChart = (data, title) => (
    <Box mb={4} borderWidth={1} borderRadius="md" p={4}>
      <Text fontSize="xl" mb={2}>{title}</Text>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <RechartsTooltip />
          <Legend />
          <Bar dataKey="orderValue" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  const renderWaiterReport = () => (
    <>
      {renderBarChart(waiterPerformance.slice(0, 5), "Top 5 Waiters by Order Value")}
      {renderBarChart(waiterPerformance.slice(-5).reverse(), "Bottom 5 Waiters by Order Value")}
    </>
  );

  const renderItemsReport = () => (
    <Box mb={4} borderWidth={1} borderRadius="md" p={4}>
      <Text fontSize="xl" mb={2}>Top 10 Dishes Sold</Text>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={topDishes}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis label={{ value: 'Percentage', angle: -90, position: 'insideLeft' }} />
          <RechartsTooltip />
          <Bar dataKey="percentage" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );

  const renderDaybook = () => (
    <Table variant="simple">
      <Thead>
        <Tr>
          <Th>Date</Th>
          <Th>Order ID</Th>
          <Th>Value</Th>
          <Th>Time</Th>
          <Th>Set Number</Th>
          <Th>Bill Number</Th>
          <Th>Items</Th>
        </Tr>
      </Thead>
      <Tbody>
        {orders.map((order) => (
          <Tr key={order.id}>
            <Td>{new Date(order.orderDate).toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' })}</Td>
            <Td>{order.id}</Td>
            <Td>{order.totalOrderAmount}</Td>
            <Td>{order.orderTime}</Td>
            <Td>{order.setNumber}</Td>
            <Td>{order.billNumber}</Td>
            <Td>
              <Tooltip label={order.orderItems.map(item => item.menuItem.name).join(', ')} aria-label="Items Tooltip">
                <span>
                  {order.orderItems.map(item => item.menuItem.name).join(', ').length > 20
                    ? `${order.orderItems.map(item => item.menuItem.name).join(', ').substring(0, 20)}...`
                    : order.orderItems.map(item => item.menuItem.name).join(', ')}
                </span>
              </Tooltip>
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  return (
    <Flex height="100vh" overflow="hidden">
      <Sidebar />
      <Box 
        flex="1" 
        p={4} 
        bg={colorMode === 'dark' ? 'gray.800' : 'gray.50'}
        ml={{ base: 0, md: '250px' }}
        transition="all 0.3s ease-in-out"
        overflowY="auto"
      >
        <Text
          fontSize="2.5rem"
          fontWeight="700"
          bgGradient="linear(to-r, #6c5ce7, #00cec9)"
          bgClip="text"
          mb={6}
        >
          Reports
        </Text>
        <Flex direction="row" align="center" mb={4}>
          {activeTab !== 'daybook' ? (
            <>
              <Text mr={2}>Start Date:</Text>
              <Input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} width="150px" />
              <Text mx={2}>End Date:</Text>
              <Input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} width="150px" />
            </>
          ) : (
            <>
              <Text mr={2}>Date:</Text>
              <Input type="date" value={singleDate} onChange={(e) => setSingleDate(e.target.value)} width="150px" />
            </>
          )}
          {activeTab === 'daybook' && (
            <Button ml={4} colorScheme="red" onClick={handleDelete}>
              <FaTrash />
            </Button>
          )}
        </Flex>
        <Tabs onChange={(index) => setActiveTab(['waiter', 'items', 'daybook'][index])} isFitted variant="enclosed">
          <TabList mb="1em">
            <Tab>Waiter Report</Tab>
            <Tab>Items Report</Tab>
            <Tab>Daybook</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              {renderWaiterReport()}
            </TabPanel>
            <TabPanel>
              {renderItemsReport()}
            </TabPanel>
            <TabPanel>
              {renderDaybook()}
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Flex>
  );
};

export default Report;