import axios from 'axios';

const API_BASE_URL = 'https://api.hotelmadhuban.co.in:443';

export const apiCall = async (endpoint, method, token, data = null) => {
  try {
    const config = {
      headers: { Authorization: `Bearer ${token}` }
    };
    let response;
    
    switch (method.toUpperCase()) {
      case 'GET':
        response = await axios.get(`${API_BASE_URL}${endpoint}`, config);
        break;
      case 'POST':
        response = await axios.post(`${API_BASE_URL}${endpoint}`, data, config);
        break;
      case 'PUT':
        response = await axios.put(`${API_BASE_URL}${endpoint}`, data, config);
        break;
      case 'DELETE':
        response = await axios.delete(`${API_BASE_URL}${endpoint}`, config);
        break;
      default:
        throw new Error(`Unsupported HTTP method: ${method}`);
    }

    return response.data;
  } catch (error) {
    console.error('API call failed:', error);
    throw error;
  }
};