// src/pages/Page2.js
import React from 'react';
import { Box, Text } from '@chakra-ui/react';

const Page2 = () => {
  return (
    <Box>
      <Text>Page 2 Content</Text>
    </Box>
  );
};

export default Page2;
