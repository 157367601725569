// import React from 'react';
// import './PrintBill.css';

// function PrintBill({ orders, tableId }) {
//   const order = orders[0]; // Assuming we're dealing with a single order
//   const date = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

//   const calculateTotal = () => {
//     const subtotal = order.orderItems.reduce((total, item) => total + item.menuItem.price * item.quantity, 0);
//     const cgst = Math.round(subtotal * 0.025 * 100) / 100;
//     const sgst = Math.round(subtotal * 0.025 * 100) / 100;
//     const total = Math.round((subtotal + cgst + sgst) * 100) / 100;
//     return { subtotal, cgst, sgst, total };
//   };

//   const { subtotal, cgst, sgst, total } = calculateTotal();

//   return (
//     <div className="print-bill">
//       <div className="bill-header">
//         <p className="mc-no">M/C NO 1</p>
//         <h1>HOTEL MADHUBAN</h1>
//         <p>JALNA</p>
//         <p>FSSAI 11516345000363</p>
//         <p className="separator">. . . . TAX/ INVOICE . . . .</p>
//         <p>TABLE KOT5 {tableId}   CUR 01   </p>
//         <p>WAITER {order.staffName}</p>
//         <p>NO.009542      DATE:{date}</p>
//         <p className="separator">. . . . . . . DUPLICATE COPY . . . . . ..</p>
//       </div>
//       <table className="bill-items">
//         <thead>
//           <tr>
//             <th>DESCRIPTION</th>
//             <th>QTY</th>
//             <th>RATE</th>
//             <th>AMOUNT</th>
//           </tr>
//         </thead>
//         <tbody>
//           {order.orderItems.map((item, index) => (
//             <tr key={index}>
//               <td>{item.menuItem.name.toUpperCase()}</td>
//               <td>{item.quantity.toFixed(2)}</td>
//               <td>{item.menuItem.price.toFixed(2)}</td>
//               <td>{(item.quantity * item.menuItem.price).toFixed(2)}</td>
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <div className="bill-summary">
//         <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
//         <p>SUB-TOT ITM: {order.orderItems.length} {subtotal.toFixed(2)}</p>
//         <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
//         <p>CGST @ 2.50% ON {subtotal.toFixed(2)} {cgst.toFixed(2)}</p>
//         <p>SGST @ 2.50% ON {subtotal.toFixed(2)} {sgst.toFixed(2)}</p>
//         <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
//         <p>TOTAL GST {(cgst + sgst).toFixed(2)}</p>
//         <p className="total">BILL TOT &lt;ROUNDED&gt; {total.toFixed(2)}</p>
//         <p>CASH {total.toFixed(2)}</p>
//       </div>
//       <div className="bill-footer">
//         <p>KOT-NO 0129 0131</p>
//         <p>GST-NO:27AAAFH8828F1ZB</p>
//         <p className="mc-no">M/C NO 1</p>
//       </div>
//     </div>
//   );
// }

// export default PrintBill;

import React from 'react';
import './PrintBill.css';

function PrintBill({ orders, tableId }) {
  const order = orders[0]; // Assuming we're dealing with a single order
  const date = new Date().toLocaleDateString('en-GB', { day: '2-digit', month: '2-digit', year: 'numeric' });

  const calculateTotal = () => {
    const subtotal = order.orderItems.reduce((total, item) => total + item.menuItem.price * item.quantity, 0);
    const cgst = Math.round(subtotal * 0.025 * 100) / 100;
    const sgst = Math.round(subtotal * 0.025 * 100) / 100;
    const total = Math.round(subtotal + cgst + sgst);
    return { subtotal, cgst, sgst, total };
  };

  const { subtotal, cgst, sgst, total } = calculateTotal();

  return (
    <div className="print-bill">
      <div className="bill-header">
        <p className="mc-no">M/C NO 1</p>
        <h1>HOTEL MADHUBAN</h1>
        <p>JALNA</p>
        <p>FSSAI 11516345000363</p>
        <p className="separator">. . . . TAX/ INVOICE . . . .</p>
        <p>TABLE KOT5 {tableId}   CUR 01   </p>
        <p>WAITER {order.staffName}</p>
        <p>NO.009542      DATE:{date}</p>
        <p className="separator">. . . . . . . DUPLICATE COPY . . . . . ..</p>
      </div>
      <table className="bill-items">
        <thead>
          <tr>
            <th>DESCRIPTION</th>
            <th>QTY</th>
            <th>RATE</th>
            <th>AMOUNT</th>
          </tr>
        </thead>
        <tbody>
          {order.orderItems.map((item, index) => (
            <tr key={index}>
              <td>{item.menuItem.name.toUpperCase()}</td>
              <td>{item.quantity.toFixed(2)}</td>
              <td>{item.menuItem.price.toFixed(2)}</td>
              <td>{(item.quantity * item.menuItem.price).toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="bill-summary">
        <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
        <p>SUB-TOT ITM: {order.orderItems.length} {subtotal.toFixed(2)}</p>
        <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
        <p>CGST @ 2.50% ON {subtotal.toFixed(2)} {cgst.toFixed(2)}</p>
        <p>SGST @ 2.50% ON {subtotal.toFixed(2)} {sgst.toFixed(2)}</p>
        <p className="separator">. . . . . . . . . . . . . . . . . . . . .</p>
        <p>TOTAL GST {(cgst + sgst).toFixed(2)}</p>
        <p className="total">BILL TOT &lt;ROUNDED&gt; {total.toFixed(2)}</p>
        <p>CASH {total.toFixed(2)}</p>
      </div>
      <div className="bill-footer">
        <p>KOT-NO 0129 0131</p>
        <p>GST-NO:27AAAFH8828F1ZB</p>
        <p className="mc-no">M/C NO 1</p>
      </div>
    </div>
  );
}

export default PrintBill;