
import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  Box,
  Flex,
  Text,
  Input,
  InputGroup,
  InputRightElement,
  Button,
  VStack,
  HStack,
  Badge,
  useColorModeValue,
  Icon,
  SimpleGrid,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Textarea,
  IconButton,
} from '@chakra-ui/react';
import { FaSearch, FaCheckCircle, FaTruck, FaClock, FaUtensils, FaCheck, FaTimes, FaExclamationTriangle } from 'react-icons/fa';
import ChefSidebar from '../components/ChefSidebar';

const OrderCard = React.memo(({ order, status, onStatusChange, onRejectAll, onMoveToPending }) => {
  const cardBg = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const itemBg = useColorModeValue('gray.50', 'gray.600');
  const gradientColor = useColorModeValue(
    'linear(to-r, purple.100, cyan.100)',
    'linear(to-r, purple.900, cyan.900)'
  );

  const filteredItems = order.orderItems.filter(item => {
    const itemStatus = item.preparationStatus ? item.preparationStatus.toLowerCase() : 'pending';
    if (item.isApprove === false && item.preparationStatus === null) return status === 'rejected';
    if (item.isApprove === false && itemStatus === 'pending') return status === 'pending';
    return itemStatus === status;
  });

  return (
    <Box
      bg={cardBg}
      borderRadius="lg"
      overflow="hidden"
      boxShadow="md"
      position="relative"
    >
      <Box
        bgGradient={gradientColor}
        h="4px"
        position="absolute"
        top={0}
        left={0}
        right={0}
      />
      <Box p={4}>
        <Flex justify="space-between" align="center" mb={3}>
          <Text fontWeight="bold" color={textColor}>Order #{order.id}</Text>
          <Badge colorScheme="orange">Table {order.tableNumber}</Badge>
        </Flex>
        <VStack align="stretch" spacing={3}>
          {filteredItems.map(item => (
            <Box key={item.id} bg={itemBg} p={2} borderRadius="md">
              <Flex justify="space-between" align="center">
                <Text color={textColor}>{item.menuItem.name} x{item.quantity}</Text>
                <HStack>
                  {status !== 'delivered' && status !== 'rejected' && (
                    <>
                      <IconButton
                        size="sm"
                        colorScheme="green"
                        icon={<FaCheck />}
                        onClick={() => onStatusChange(order.id, item.id, getNextStatus(status))}
                        aria-label="Accept"
                        variant="outline"
                      />
                      <IconButton
                        size="sm"
                        colorScheme="red"
                        icon={<FaTimes />}
                        onClick={() => onStatusChange(order.id, item.id, 'rejected')}
                        aria-label="Reject"
                        variant="outline"
                      />
                    </>
                  )}
                  {status === 'rejected' && (
                    <Button
                      size="sm"
                      colorScheme="blue"
                      onClick={() => onMoveToPending(item.id)}
                      variant="outline"
                    >
                      Move to Pending
                    </Button>
                  )}
                </HStack>
              </Flex>
              {item.specialInstructions && (
                <Text fontSize="sm" color="gray.500" mt={1}>Instructions: {item.specialInstructions}</Text>
              )}
              {status === 'rejected' && item.rejectReason && (
                <Text fontSize="sm" color="red.500" mt={1}>Reason: {item.rejectReason}</Text>
              )}
            </Box>
          ))}
        </VStack>
        {status !== 'delivered' && status !== 'rejected' && (
          <HStack justify="flex-end" mt={4}>
            <Button
              size="sm"
              colorScheme="green"
              onClick={() => onStatusChange(order.id, null, getNextStatus(status))}
              leftIcon={<FaCheck />}
            >
              Accept All
            </Button>
            <Button
              size="sm"
              colorScheme="red"
              onClick={() => onRejectAll(order.id, 'rejected')}
              leftIcon={<FaTimes />}
            >
              Reject All
            </Button>
          </HStack>
        )}
      </Box>
    </Box>
  );
});

const getNextStatus = (currentStatus) => {
  switch (currentStatus) {
    case 'pending': return 'preparing';
    case 'preparing': return 'ready';
    case 'ready': return 'delivered';
    case 'rejected': return 'pending';
    default: return currentStatus;
  }
};

const ChefOrders = () => {
  const [orders, setOrders] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [rejectionOrderId, setRejectionOrderId] = useState(null);
  const [rejectionItemId, setRejectionItemId] = useState(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const token = useSelector(state => state.auth.token);

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const columnBg = useColorModeValue('gray.100', 'gray.700');

  const fetchOrders = useCallback(async () => {
    try {
      const response = await axios.get('https://api.hotelmadhuban.co.in/waiter/order/getActive', {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      setOrders(response.data);
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  }, [token]);

  useEffect(() => {
    fetchOrders();
    const interval = setInterval(fetchOrders, 5000);
    return () => clearInterval(interval);
  }, [fetchOrders]);

  const handleStatusChange = async (orderId, itemId, newStatus) => {
    try {
      if (newStatus === 'rejected') {
        setRejectionOrderId(orderId);
        setRejectionItemId(itemId);
        onOpen();
      } else {
        if (itemId) {
          await updateOrderItemStatus(orderId, itemId, newStatus);
        } else {
          // Update all items in the order
          const order = orders.find(o => o.id === orderId);
          await Promise.all(order.orderItems.map(item =>
            updateOrderItemStatus(orderId, item.id, newStatus)
          ));
        }
      }
    } catch (error) {
      console.error('Error changing order status:', error);
    }
  };

  const updateOrderItemStatus = async (orderId, orderItemId, status) => {
    try {
      const payload = { orderId, orderItemId, status };
      await axios.put('https://api.hotelmadhuban.co.in/chef/order-approve/updateStatus', payload, {
        headers: { 'Authorization': `Bearer ${token}` }
      });
      fetchOrders();
    } catch (error) {
      console.error('Error updating order item status:', error);
    }
  };

  const handleReject = async () => {
    if (rejectionOrderId && rejectionReason) {
      try {
        if (rejectionItemId === 'all') {
          // If rejecting all items, get all item IDs for the order
          const order = orders.find(o => o.id === rejectionOrderId);
          const allItemIds = order.orderItems.map(item => item.id);
         
          // Make separate API calls for each item
          for (const itemId of allItemIds) {
            const payload = {
              orderId: rejectionOrderId,
              itemId: itemId,
              rejectReason: rejectionReason
            };
            await axios.put('https://api.hotelmadhuban.co.in/chef/order-approve/reject', payload, {
              headers: { 'Authorization': `Bearer ${token}` }
            });
          }
        } else {
          // For single item rejection
          const payload = {
            orderId: rejectionOrderId,
            itemId: rejectionItemId,
            rejectReason: rejectionReason
          };
          await axios.put('https://api.hotelmadhuban.co.in/chef/order-approve/reject', payload, {
            headers: { 'Authorization': `Bearer ${token}` }
          });
        }
       
        fetchOrders();
        onClose();
        setRejectionReason('');
        setRejectionOrderId(null);
        setRejectionItemId(null);
      } catch (error) {
        console.error('Error rejecting order item(s):', error);
      }
    }
  };

  const handleRejectAll = (orderId) => {
    setRejectionOrderId(orderId);
    setRejectionItemId('all');
    onOpen();
  };

  const handleMoveToPending = async (itemId) => {
    const payload = { itemId };
    try {
      await axios.put(`https://api.hotelmadhuban.co.in/order-item/cancelReject?id=${itemId}`, payload,{
        headers: { 'Authorization': `Bearer ${token}` }
      });
      fetchOrders();
    } catch (error) {
      console.error('Error moving item to pending:', error);
    }
  };
 

  const onDragEnd = async (result) => {
    if (!result.destination) return;
    const { source, destination, draggableId } = result;
    if (source.droppableId === destination.droppableId) return;

    const [orderId, itemId] = draggableId.split('-');
    await handleStatusChange(parseInt(orderId), parseInt(itemId), destination.droppableId);
  };

  const filterOrders = useCallback((status) =>
    orders.filter(order =>
      order.orderItems.some(item => {
        const itemStatus = item.preparationStatus ? item.preparationStatus.toLowerCase() : null;
        if (item.isApprove === false && itemStatus === null) return status === 'rejected';
        if (item.isApprove === false && itemStatus === 'pending') return status === 'pending';
        // Add this condition to handle new, unprocessed orders
        if (item.isApprove === null && itemStatus === null) return status === 'pending';
        return itemStatus === status;
      }) &&
      (searchQuery === '' || order.tableNumber.toString().includes(searchQuery))
    ), [orders, searchQuery]);

  const renderOrderItems = useCallback((status) => {
    return filterOrders(status).map((order, index) => (
      <Draggable key={`${order.id}`} draggableId={`${order.id}`} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
          >
            <OrderCard
              order={order}
              status={status}
              onStatusChange={handleStatusChange}
              onRejectAll={handleRejectAll}
              onMoveToPending={handleMoveToPending}
            />
          </div>
        )}
      </Draggable>
    ));
  }, [filterOrders, handleStatusChange, handleRejectAll, handleMoveToPending]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Flex>
        <ChefSidebar />
        <Box flex={1} ml={{ base: "60px", md: "60px" }} p={8} bg={bgColor} minHeight="100vh">
          <Flex justify="space-between" align="center" mb={6}>
            <Text fontSize="3xl" fontWeight="bold" color={textColor}>Chef Orders</Text>
            <Button
              leftIcon={<FaExclamationTriangle />}
              colorScheme="yellow"
              onClick={() => {
                setRejectionOrderId(null);
                onOpen();
              }}
              variant="solid"
            >
              View Rejected ({filterOrders('rejected').length})
            </Button>
          </Flex>
          <InputGroup mb={6}>
            <Input
              placeholder="Search by table number..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <InputRightElement>
              <Icon as={FaSearch} color="gray.500" />
            </InputRightElement>
          </InputGroup>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={6} overflowX="auto">
            {['pending', 'preparing', 'ready', 'delivered'].map(status => (
              <VStack key={status} align="stretch" spacing={4}>
                <HStack>
                  <Icon
                    as={status === 'pending' ? FaClock : status === 'preparing' ? FaUtensils : status === 'ready' ? FaCheckCircle : FaTruck}
                    color={status === 'pending' ? 'orange.500' : status === 'preparing' ? 'blue.500' : status === 'ready' ? 'green.500' : 'purple.500'}
                  />
                  <Text fontWeight="600" fontSize="xl" color={textColor}>{status.charAt(0).toUpperCase() + status.slice(1)}</Text>
                </HStack>
                <Droppable droppableId={status}>
                  {(provided) => (
                    <VStack
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      align="stretch"
                      spacing={4}
                      minHeight="200px"
                      bg={columnBg}
                      borderRadius="md"
                      p={2}
                    >
                      {renderOrderItems(status)}
                      {provided.placeholder}
                    </VStack>
                  )}
                </Droppable>
              </VStack>
            ))}
          </SimpleGrid>
        </Box>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Rejected Orders</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Droppable droppableId="rejected">
                {(provided) => (
                  <VStack
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    align="stretch"
                    spacing={4}
                  >
                    {renderOrderItems('rejected')}
                    {provided.placeholder}
                  </VStack>
                )}
              </Droppable>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <Modal isOpen={!!rejectionOrderId} onClose={() => setRejectionOrderId(null)}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Rejection Reason</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Textarea
                value={rejectionReason}
                onChange={(e) => setRejectionReason(e.target.value)}
                placeholder="Enter rejection reason"
              />
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={handleReject}>
                Submit
              </Button>
              <Button variant="ghost" onClick={() => setRejectionOrderId(null)}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Flex>
    </DragDropContext>
  );
};

export default ChefOrders;