// import React, { useState, useEffect } from 'react';
// import {
//   Box,
//   Button,
//   FormControl,
//   FormLabel,
//   Input,
//   VStack,
//   HStack,
//   Table,
//   Thead,
//   Tbody,
//   Tr,
//   Th,
//   Td,
//   useToast,
//   Switch,
//   Spinner,
//   Alert,
//   AlertIcon,
//   Heading,
//   Divider,
//   useDisclosure,
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   ModalHeader,
//   ModalFooter,
//   ModalBody,
//   ModalCloseButton,
//   Badge,
// } from '@chakra-ui/react';
// import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
// import { apiCall } from './apiUtils';

// const MenuConfig = ({ token }) => {
//   const [menus, setMenus] = useState([]);
//   const [newMenu, setNewMenu] = useState({ name: '', season: '', isActive: false });
//   const [editingMenu, setEditingMenu] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);
//   const toast = useToast();
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   useEffect(() => {
//     fetchMenus();
//   }, []);

//   const fetchMenus = async () => {
//     try {
//       setLoading(true);
//       const data = await apiCall('/menu/getAll', 'GET', token);
//       setMenus(data);
//       setError(null);
//     } catch (err) {
//       setError('Failed to fetch menus');
//       console.error('Error fetching menus:', err);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const handleCreate = async () => {
//     try {
//       await apiCall('/menu/create', 'POST', token, newMenu);
//       setNewMenu({ name: '', season: '', isActive: false });
//       fetchMenus();
//       onClose();
//       toast({
//         title: 'Menu created successfully',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });
//     } catch (error) {
//       toast({
//         title: 'Error creating menu',
//         description: error.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleEdit = async () => {
//     try {
//       await apiCall(`/menu/edit?id=${editingMenu.id}`, 'PUT', token, editingMenu);
//       setEditingMenu(null);
//       fetchMenus();
//       onClose();
//       toast({
//         title: 'Menu updated successfully',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });
//     } catch (error) {
//       toast({
//         title: 'Error updating menu',
//         description: error.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   };

//   const handleDelete = async (id) => {
//     try {
//       await apiCall(`/menu/delete?id=${id}`, 'DELETE', token);
//       fetchMenus();
//       toast({
//         title: 'Menu deleted successfully',
//         status: 'success',
//         duration: 3000,
//         isClosable: true,
//       });
//     } catch (error) {
//       toast({
//         title: 'Error deleting menu',
//         description: error.message,
//         status: 'error',
//         duration: 5000,
//         isClosable: true,
//       });
//     }
//   };

//   const MenuForm = ({ menuData, setMenuData, onSubmit, isEditing }) => (
//     <VStack spacing={4} align="stretch">
//       <FormControl>
//         <FormLabel>Menu Name</FormLabel>
//         <Input
//           value={menuData.name}
//           onChange={(e) => setMenuData({ ...menuData, name: e.target.value })}
//         />
//       </FormControl>
//       <FormControl>
//         <FormLabel>Season</FormLabel>
//         <Input
//           value={menuData.season}
//           onChange={(e) => setMenuData({ ...menuData, season: e.target.value })}
//         />
//       </FormControl>
//       <FormControl>
//         <FormLabel>Active</FormLabel>
//         <Switch
//           isChecked={menuData.isActive}
//           onChange={(e) => setMenuData({ ...menuData, isActive: e.target.checked })}
//         />
//       </FormControl>
//       <Button colorScheme="blue" onClick={onSubmit}>
//         {isEditing ? 'Update Menu' : 'Create Menu'}
//       </Button>
//     </VStack>
//   );

//   if (loading) return <Spinner />;
//   if (error) return <Alert status="error"><AlertIcon />{error}</Alert>;

//   return (
//     <Box>
//       <VStack spacing={4} align="stretch">
//         <HStack justifyContent="space-between">
//           <Heading size="lg">Menu Configurations</Heading>
//           <Button leftIcon={<AddIcon />} colorScheme="green" onClick={onOpen}>
//             Add New Menu
//           </Button>
//         </HStack>
//         <Divider />
//         <Table variant="simple">
//           <Thead>
//             <Tr>
//               <Th>Menu Name</Th>
//               <Th>Season</Th>
//               <Th>Active</Th>
//               <Th>Actions</Th>
//             </Tr>
//           </Thead>
//           <Tbody>
//             {menus.map((menu) => (
//               <Tr key={menu.id}>
//                 <Td>{menu.name}</Td>
//                 <Td>{menu.season}</Td>
//                 <Td>
//                   <Badge colorScheme={menu.isActive ? "green" : "red"}>
//                     {menu.isActive ? "Active" : "Inactive"}
//                   </Badge>
//                 </Td>
//                 <Td>
//                   <HStack spacing={2}>
//                     <Button
//                       size="sm"
//                       leftIcon={<EditIcon />}
//                       onClick={() => {
//                         setEditingMenu(menu);
//                         onOpen();
//                       }}
//                     >
//                       Edit
//                     </Button>
//                     <Button
//                       size="sm"
//                       leftIcon={<DeleteIcon />}
//                       colorScheme="red"
//                       onClick={() => handleDelete(menu.id)}
//                     >
//                       Delete
//                     </Button>
//                   </HStack>
//                 </Td>
//               </Tr>
//             ))}
//           </Tbody>
//         </Table>
//       </VStack>

//       <Modal isOpen={isOpen} onClose={onClose}>
//         <ModalOverlay />
//         <ModalContent>
//           <ModalHeader>{editingMenu ? 'Edit Menu' : 'Add New Menu'}</ModalHeader>
//           <ModalCloseButton />
//           <ModalBody>
//             <MenuForm
//               menuData={editingMenu || newMenu}
//               setMenuData={editingMenu ? setEditingMenu : setNewMenu}
//               onSubmit={editingMenu ? handleEdit : handleCreate}
//               isEditing={!!editingMenu}
//             />
//           </ModalBody>
//           <ModalFooter>
//             <Button variant="ghost" mr={3} onClick={onClose}>
//               Cancel
//             </Button>
//           </ModalFooter>
//         </ModalContent>
//       </Modal>
//     </Box>
//   );
// };

// export default MenuConfig;

import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Switch,
  Spinner,
  Alert,
  AlertIcon,
  Heading,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Badge,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { apiCall } from './apiUtils';

const MenuConfig = ({ token }) => {
  const [menus, setMenus] = useState([]);
  const [formData, setFormData] = useState({ name: '', season: '', isActive: false });
  const [editingMenuId, setEditingMenuId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    fetchMenus();
  }, []);

  useEffect(() => {
    if (editingMenuId) {
      const menu = menus.find(menu => menu.id === editingMenuId);
      if (menu) {
        setFormData(menu);
      }
    } else {
      resetFormData();
    }
  }, [editingMenuId]);

  const fetchMenus = async () => {
    try {
      setLoading(true);
      const data = await apiCall('/menu/getAll', 'GET', token);
      setMenus(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch menus');
      console.error('Error fetching menus:', err);
    } finally {
      setLoading(false);
    }
  };

  const resetFormData = () => {
    setFormData({ name: '', season: '', isActive: false });
  };

  const handleCreate = async () => {
    try {
      await apiCall('/menu/create', 'POST', token, formData);
      resetFormData();
      fetchMenus();
      onClose();
      toast({
        title: 'Menu created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error creating menu',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleEdit = async () => {
    try {
      await apiCall(`/menu/edit?id=${editingMenuId}`, 'PUT', token, formData);
      setEditingMenuId(null);
      fetchMenus();
      onClose();
      toast({
        title: 'Menu updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating menu',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiCall(`/menu/delete?id=${id}`, 'DELETE', token);
      fetchMenus();
      toast({
        title: 'Menu deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting menu',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData(prevData => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSubmit = () => {
    if (editingMenuId) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Alert status="error"><AlertIcon />{error}</Alert>;

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <HStack justifyContent="space-between">
          <Heading size="lg">Menu Configurations</Heading>
          <Button leftIcon={<AddIcon />} colorScheme="green" onClick={onOpen}>
            Add New Menu
          </Button>
        </HStack>
        <Divider />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Menu Name</Th>
              <Th>Season</Th>
              <Th>Active</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {menus.map((menu) => (
              <Tr key={menu.id}>
                <Td>{menu.name}</Td>
                <Td>{menu.season}</Td>
                <Td>
                  <Badge colorScheme={menu.isActive ? "green" : "red"}>
                    {menu.isActive ? "Active" : "Inactive"}
                  </Badge>
                </Td>
                <Td>
                  <HStack spacing={2}>
                    <Button
                      size="sm"
                      leftIcon={<EditIcon />}
                      onClick={() => {
                        setEditingMenuId(menu.id);
                        onOpen();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      leftIcon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => handleDelete(menu.id)}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingMenuId ? 'Edit Menu' : 'Add New Menu'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Menu Name</FormLabel>
                <Input
                  value={formData.name}
                  onChange={(e) => handleChange('name', e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Season</FormLabel>
                <Input
                  value={formData.season}
                  onChange={(e) => handleChange('season', e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Active</FormLabel>
                <Switch
                  isChecked={formData.isActive}
                  onChange={(e) => handleChange('isActive', e.target.checked)}
                />
              </FormControl>
              <Button colorScheme="blue" onClick={handleSubmit}>
                {editingMenuId ? 'Update Menu' : 'Create Menu'}
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default MenuConfig;
