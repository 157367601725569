import React from 'react';
import { Box, Text, VStack, HStack, Badge, useColorModeValue } from '@chakra-ui/react';
import { FaUser, FaPhone } from 'react-icons/fa';

// Utility function to convert string to sentence case
const toSentenceCase = (str) => {
  if (!str) return '';
  return str.toLowerCase().split(' ').map(word => 
    word.charAt(0).toUpperCase() + word.slice(1)
  ).join(' ');
};

const RoomCard = ({ number, status, customerName, phoneNumber, onClick }) => {
  const textColor = useColorModeValue('gray.800', 'white');
  const borderColor = useColorModeValue('blue.200', 'blue.600');
  const secondaryBgColor = useColorModeValue('gray.50', 'gray.600');
  const iconColor = useColorModeValue('gray.600', 'gray.400');
  const gradientStart = useColorModeValue('#6c5ce7', '#4834d4');
  const gradientEnd = useColorModeValue('#00cec9', '#00b894');

  return (
    <Box
      borderRadius="lg"
      borderWidth="1px"
      borderColor={borderColor}
      overflow="hidden"
      onClick={onClick}
      cursor="pointer"
      transition="all 0.3s"
      _hover={{ transform: 'translateY(-2px)', boxShadow: 'md' }}
    >
      <Box
        h="4px"
        bgGradient={`linear(to-r, ${gradientStart}, ${gradientEnd})`}
      />
      <Box p={3} borderBottomWidth="1px" borderColor={borderColor}>
        <HStack justify="space-between">
          <Text fontWeight="bold" fontSize="lg" color={textColor}>
            Room #{number}
          </Text>
          <Badge colorScheme={status === 'vacant' ? 'green' : 'red'} borderRadius="full" px={2}>
            {status}
          </Badge>
        </HStack>
      </Box>
      <Box p={3} bg={secondaryBgColor}>
        <VStack align="stretch" spacing={2}>
          {customerName && (
            <HStack>
              <FaUser color={iconColor} />
              <Text fontSize="sm" color={textColor}>{toSentenceCase(customerName)}</Text>
            </HStack>
          )}
          {phoneNumber && (
            <HStack>
              <FaPhone color={iconColor} />
              <Text fontSize="sm" color={textColor}>{phoneNumber}</Text>
            </HStack>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default RoomCard;