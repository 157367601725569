import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Text,
  Select,
  Input,
  useColorMode,
  useToast,
  Box,
} from '@chakra-ui/react';

const BillingModal = ({ order, isOpen, onClose, token, onPaymentSuccess }) => {
  const [paymentMode, setPaymentMode] = useState('');
  const [amountPaid, setAmountPaid] = useState('');
  const [errors, setErrors] = useState({});
  const [remainingBalance, setRemainingBalance] = useState(0);
  const [paidAmount, setPaidAmount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { colorMode } = useColorMode();

  useEffect(() => {
    if (isOpen) {
      fetchRemainingBalance();
    }
  }, [isOpen, order.reservationId, token]);

  const fetchRemainingBalance = async () => {
    try {
      const response = await axios.get(
        `https://api.hotelmadhuban.co.in/bill/getByReservation?id=${order.reservationId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRemainingBalance(response.data.pendingAmount);
      setPaidAmount(response.data.paidAmount);
    } catch (error) {
      console.error('Failed to fetch remaining balance:', error);
    }
  };

  const handlePayment = async () => {
    if (!order || !order.reservationId) {
      toast({
        title: "Error",
        description: "Invalid order data. Unable to process payment.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsLoading(true);
    try {
      const response = await axios.put(
        'https://api.hotelmadhuban.co.in:443/bill/payBill?pay=true',
        {
          amountPaid: parseFloat(amountPaid),
          paymentMode,
          reservationId: order.reservationId,
          orderId: order.id
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data === 'Bill Updated!') {
        toast({
          title: "Payment Successful",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onPaymentSuccess();
        onClose();
      } else {
        throw new Error('Failed to process payment');
      }
    } catch (error) {
      console.error('Failed to process payment:', error);
      toast({
        title: "Failed to process payment",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentModeChange = (e) => {
    setPaymentMode(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      paymentMode: '',
    }));
  };

  const handleAmountPaidChange = (e) => {
    setAmountPaid(e.target.value);
    setErrors((prevErrors) => ({
      ...prevErrors,
      amountPaid: '',
    }));
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent bg={colorMode === 'dark' ? 'gray.800' : 'white'}>
        <ModalHeader>Billing Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack spacing={4} align="stretch">
            <Text fontWeight="bold">Table: {order.tableNumber}</Text>
            <Box borderWidth={1} borderRadius="md" p={3}>
              {order.orderItems.map((item, index) => (
                <HStack key={index} justifyContent="space-between">
                  <Text>{item.menuItem.name} x {item.quantity}</Text>
                  <Text>₹{(item.menuItem.price * item.quantity).toFixed(2)}</Text>
                </HStack>
              ))}
            </Box>
            <HStack justifyContent="space-between">
              <Text fontWeight="bold">Grand Total:</Text>
              <Text fontWeight="bold">₹{(order.totalOrderAmount - order.discount).toFixed(2)}</Text>
            </HStack>
            {remainingBalance > 0 && (
              <VStack align="stretch">
                <HStack justifyContent="space-between">
                  <Text>Amount Paid:</Text>
                  <Text>₹{paidAmount}</Text>
                </HStack>
                <HStack justifyContent="space-between">
                  <Text>Remaining Balance:</Text>
                  <Text>₹{remainingBalance.toFixed(2)}</Text>
                </HStack>
              </VStack>
            )}
            <Select placeholder="Select Payment Mode" value={paymentMode} onChange={handlePaymentModeChange}>
              <option value="cash">Cash</option>
              <option value="upi">UPI</option>
              <option value="credit_card">Credit Card</option>
              <option value="debit_card">Debit Card</option>
            </Select>
            {errors.paymentMode && <Text color="red.500">{errors.paymentMode}</Text>}
            <Input
              type="number"
              placeholder="Enter Amount"
              value={amountPaid}
              onChange={handleAmountPaidChange}
            />
            {errors.amountPaid && <Text color="red.500">{errors.amountPaid}</Text>}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={handlePayment} isLoading={isLoading}>
            Pay Now
          </Button>
          <Button variant="ghost" onClick={onClose}>Pay Later</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default BillingModal;