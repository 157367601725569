import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import Orders from './pages/Orders';
import AllOrders from './pages/AllOrders';
import TableListPage from './pages/TableListPage';
import TableDetailsPage from './pages/TableDetailsPage';
import BillPage from './pages/BillPage';
import MenuPage from './pages/MenuPage';
import ChefOrders from './pages/ChefOrders';
import OrderDetailsPage from './pages/OrderDetailsPage';
import SettingsPage from './pages/SettingsPage';
import Report from './pages/Report';

const PrivateRoute = ({ component: Component, allowedRoles, ...rest }) => {
  const { token, role } = useSelector((state) => state.auth);

  return (
    <Route
      {...rest}
      render={(props) =>
        token && allowedRoles.includes(role) ? (
          <Component {...props} />
        ) : (
          <Redirect to="/" />
        )
      }
    />
  );
};

function App() {
  const { role } = useSelector((state) => state.auth);

  return (
    <Switch>
      <Route exact path="/" component={Login} />
      
      {/* Routes for all authenticated users */}
       
      {/* Chef-specific route */}
      <PrivateRoute path="/chef-orders" component={ChefOrders} allowedRoles={['CHEF', 'ADMIN', 'RECEPTION']} />
      
      {/* Routes for Waiter, Admin, and Reception */}
      <PrivateRoute path="/table-list" component={TableListPage} allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} />
      <PrivateRoute path="/orders" component={Orders} allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} />
      <PrivateRoute path="/menu" component={MenuPage} allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} />
      <PrivateRoute path="/order-details" component={OrderDetailsPage} allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} />
      <PrivateRoute path="/table/:id" component={TableDetailsPage} allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} />
      
      <PrivateRoute path="/reception-bill/:id" component={BillPage} allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} />


      <PrivateRoute 
        path="/waiter-bill/:id" 
        render={(props) => <BillPage {...props} isReception={false} />} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />
      
      {/* Routes for Admin and Reception only */}
      <PrivateRoute path="/dashboard" component={Dashboard} allowedRoles={['ADMIN', 'RECEPTION']} />
      <PrivateRoute path="/all-orders" component={AllOrders} allowedRoles={['ADMIN', 'RECEPTION']} />
      <PrivateRoute 
        path="/reception-bill/" 
        render={(props) => <BillPage {...props} isReception={true} />} 
        allowedRoles={['ADMIN', 'RECEPTION']} 
      />
     <PrivateRoute 
        path="/report" 
        component={Report} 
        allowedRoles={['WAITER', 'ADMIN', 'RECEPTION']} 
      />
      {/* Settings route for Admin only */}
      <PrivateRoute path="/settings" component={SettingsPage} allowedRoles={['ADMIN']} />
      
      {/* Redirect to login if no match */}
      <Redirect to="/" />
    </Switch>
  );
}

export default App;