import React from 'react';
import './FilterCategories.css';

const FilterCategories = ({ categories, onCategoryClick, activeCategory }) => {
  const allCategory = { name: 'All', icon: 'fas fa-list' };

  return (
    <div className="filter-container">
      <div className="filter-categories">
        <div
          className={`category ${activeCategory === allCategory.name ? 'active' : ''}`}
          onClick={() => onCategoryClick(allCategory.name)}
        >
          <i className={allCategory.icon}></i>
          <span>{allCategory.name}</span>
        </div>
        {categories.map((category, index) => (
          <div
            key={index}
            className={`category ${activeCategory === category.name ? 'active' : ''}`}
            onClick={() => onCategoryClick(category.name)}
          >
            <i className={category.icon}></i>
            <span>{category.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FilterCategories;
