// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { ChakraProvider, extendTheme, CSSReset } from '@chakra-ui/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import App from './App';

const theme = extendTheme({
  fonts: {
    body: 'Poppins, sans-serif',
  },
  styles: {
    global: (props) => ({
      body: {
        bg: props.colorMode === 'dark' ? '#2d3748' : '#f0f4f8',
        color: props.colorMode === 'dark' ? '#f0f4f8' : '#2d3748',
        transition: 'background-color 0.3s ease',
      },
    }),
  },
});

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <Router>
          <CSSReset />
          <App />
        </Router>
      </Provider>
    </ChakraProvider>
  </React.StrictMode>
);