import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useToast,
  Select,
  Spinner,
  Alert,
  AlertIcon,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Heading,
  Divider,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useColorModeValue,
  WarningIcon,
} from '@chakra-ui/react';
import { AddIcon, EditIcon, DeleteIcon } from '@chakra-ui/icons';
import { apiCall } from './apiUtils';

const StaffManagement = ({ token }) => {
  const [staff, setStaff] = useState([]);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    primaryContact: '',
    status: 'active',
    department: '',
    email: '',
    role: '',
    addressLine1: '',
    addressLine2: '',
    city: '',
    state: '',
    country: '',
    pincode: '',
    dateOfJoining: '',
    userDto: {
      username: '',
      passwordPlain: ''
    }
  });
  const [editingStaff, setEditingStaff] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const toast = useToast();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showPasswordChange, setShowPasswordChange] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const readOnlyBg = useColorModeValue("gray.100", "whiteAlpha.200");
  const readOnlyColor = useColorModeValue("gray.800", "whiteAlpha.900");

  const resetFormData = () => {
    setFormData({
      firstName: '',
      lastName: '',
      primaryContact: '',
      status: 'active',
      department: '',
      email: '',
      role: '',
      addressLine1: '',
      addressLine2: '',
      city: '',
      state: '',
      country: '',
      pincode: '',
      dateOfJoining: '',
      userDto: {
        username: '',
        passwordPlain: ''
      }
    });
  };

  const handleModalClose = () => {
    resetFormData();
    setEditingStaff(null);
    setShowPasswordChange(false);
    onClose();
  };

  const handleAddNewStaff = () => {
    resetFormData();
    setEditingStaff(null);
    onOpen();
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  useEffect(() => {
    if (editingStaff) {
      setFormData({
        ...editingStaff,
        userDto: {
          username: editingStaff.userDto?.username || '',
          passwordPlain: editingStaff.userDto?.passwordPlain || ''
        }
      });
    } else {
      setFormData({
        firstName: '',
        lastName: '',
        primaryContact: '',
        status: 'active',
        department: '',
        email: '',
        role: '',
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        dateOfJoining: '',
        userDto: {
          username: '',
          passwordPlain: ''
        }
      });
    }
  }, [editingStaff]);

  const fetchStaff = async () => {
    try {
      setLoading(true);
      const data = await apiCall('/staff/getAll', 'GET', token);
      setStaff(data);
      setError(null);
    } catch (err) {
      setError('Failed to fetch staff');
      console.error('Error fetching staff:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleCreate = async () => {
    try {
      const response = await apiCall('/staff/create', 'POST', token, formData);
      const employeeId = response; // assuming the response contains the employeeId
      console.log('employee id',employeeId)
      const userPayload = {
        username: formData.userDto.username,
        password: formData.userDto.passwordPlain,
        roles: formData.role.toUpperCase(), // ensure the role is in uppercase
        employeeId
      };
 
      await apiCall('/user/manage/create', 'POST', token, userPayload);
      fetchStaff();
      onClose();
      toast({
        title: 'Staff member created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error creating staff member',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };
 

  const handleEdit = async () => {
    try {
      await apiCall(`/staff/edit?id=${formData.id}`, 'PUT', token, formData);
     
      if (showPasswordChange && newPassword) {
        const passwordUpdatePayload = {
          username: editingStaff.userDto.username,
          password: newPassword
        };
        await apiCall('/user/manage/update', 'PUT', token, passwordUpdatePayload);
      }

      setEditingStaff(null);
      setNewPassword('');
      setShowPasswordChange(false);
      fetchStaff();
      onClose();
      toast({
        title: 'Staff member updated successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error updating staff member',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleDelete = async (id) => {
    try {
      await apiCall(`/staff/delete?id=${id}`, 'DELETE', token);
      fetchStaff();
      toast({
        title: 'Staff member deleted successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Error deleting staff member',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleChange = (field, value) => {
    if (field.includes('.')) {
      const [parent, child] = field.split('.');
      setFormData(prevData => ({
        ...prevData,
        [parent]: {
          ...prevData[parent],
          [child]: value
        }
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [field]: value,
      }));
    }
  };

  const handleSubmit = () => {
    if (!formData.firstName || !formData.lastName || !formData.primaryContact) {
      toast({
        title: 'Required fields missing',
        description: 'Please fill in First Name, Last Name, and Phone.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    if (editingStaff) {
      handleEdit();
    } else {
      handleCreate();
    }
  };

  if (loading) return <Spinner />;
  if (error) return <Alert status="error"><AlertIcon />{error}</Alert>;

  return (
    <Box>
      <VStack spacing={4} align="stretch">
        <HStack justifyContent="space-between">
          <Heading size="lg">Staff Management</Heading>
          <Button leftIcon={<AddIcon />} colorScheme="green" onClick={handleAddNewStaff}>
            Add New Staff
          </Button>
        </HStack>
        <Divider />
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Phone</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Username</Th>
              <Th>Password</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody>
            {staff.map((member) => (
              <Tr key={member.id}>
                <Td>{`${member.firstName} ${member.lastName || ''}`}</Td>
                <Td>{member.primaryContact}</Td>
                <Td>{member.email}</Td>
                <Td>{member.role}</Td>
                <Td>{member.userDto?.username || ''}</Td>
                <Td>{member.userDto?.passwordPlain || ''}</Td>
                <Td>{member.status}</Td>
                <Td>
                  <HStack spacing={2}>
                    <Button
                      size="sm"
                      leftIcon={<EditIcon />}
                      onClick={() => {
                        setEditingStaff(member);
                        onOpen();
                      }}
                    >
                      Edit
                    </Button>
                    <Button
                      size="sm"
                      leftIcon={<DeleteIcon />}
                      colorScheme="red"
                      onClick={() => handleDelete(member.id)}
                    >
                      Delete
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </VStack>

      <Modal isOpen={isOpen} onClose={handleModalClose} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{editingStaff ? 'Edit Staff Member' : 'Add New Staff Member'}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={4} align="stretch">
              <SimpleGrid columns={2} spacing={4}>
                <FormControl isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    value={formData.firstName}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    value={formData.lastName}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                  />
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={4}>
                <FormControl isRequired>
                  <FormLabel>Phone</FormLabel>
                  <Input
                    value={formData.primaryContact}
                    onChange={(e) => handleChange('primaryContact', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Email</FormLabel>
                  <Input
                    value={formData.email}
                    onChange={(e) => handleChange('email', e.target.value)}
                  />
                </FormControl>
              </SimpleGrid>
              <SimpleGrid columns={2} spacing={4}>
                <FormControl>
                  <FormLabel>Username</FormLabel>
                  <Input
                    value={editingStaff ? editingStaff.userDto.username : formData.userDto.username}
                    onChange={(e) => !editingStaff && handleChange('userDto.username', e.target.value)}
                    isReadOnly={!!editingStaff}
                    bg={editingStaff ? readOnlyBg : "transparent"}
                    color={editingStaff ? readOnlyColor : "inherit"}
                    _hover={{ bg: editingStaff ? readOnlyBg : "transparent" }}
                  />
                </FormControl>
                {editingStaff ? (
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    {showPasswordChange ? (
                      <Input
                        type="password"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        placeholder="Enter new password"
                      />
                    ) : (
                      <Button onClick={() => setShowPasswordChange(true)}>
                        Change Password
                      </Button>
                    )}
                  </FormControl>
                ) : (
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      value={formData.userDto.passwordPlain}
                      onChange={(e) => handleChange('userDto.passwordPlain', e.target.value)}
                    />
                  </FormControl>
                )}
              </SimpleGrid>
              <SimpleGrid columns={3} spacing={4}>
                <FormControl>
                  <FormLabel>Status</FormLabel>
                  <Select
                    value={formData.status}
                    onChange={(e) => handleChange('status', e.target.value)}
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Select>
                </FormControl>
                <FormControl>
                  <FormLabel>Department</FormLabel>
                  <Input
                    value={formData.department}
                    onChange={(e) => handleChange('department', e.target.value)}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel>Role</FormLabel>
                  <Select
                    value={formData.role}
                    onChange={(e) => handleChange('role', e.target.value)}
                  >
                    <option value="chef">Chef</option>
                    <option value="waiter">Waiter</option>
                    <option value="receptionist">Receptionist</option>
                  </Select>
                </FormControl>
              </SimpleGrid>
              <Accordion allowToggle>
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        Address Details
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <SimpleGrid columns={2} spacing={4}>
                      <FormControl>
                        <FormLabel>Address Line 1</FormLabel>
                        <Input
                          value={formData.addressLine1}
                          onChange={(e) => handleChange('addressLine1', e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Address Line 2</FormLabel>
                        <Input
                          value={formData.addressLine2}
                          onChange={(e) => handleChange('addressLine2', e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>City</FormLabel>
                        <Input
                          value={formData.city}
                          onChange={(e) => handleChange('city', e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>State</FormLabel>
                        <Input
                          value={formData.state}
                          onChange={(e) => handleChange('state', e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Country</FormLabel>
                        <Input
                          value={formData.country}
                          onChange={(e) => handleChange('country', e.target.value)}
                        />
                      </FormControl>
                      <FormControl>
                        <FormLabel>Pincode</FormLabel>
                        <Input
                          value={formData.pincode}
                          onChange={(e) => handleChange('pincode', e.target.value)}
                        />
                      </FormControl>
                    </SimpleGrid>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
              <FormControl>
                <FormLabel>Date of Joining</FormLabel>
                <Input
                  type="date"
                  value={formData.dateOfJoining}
                  onChange={(e) => handleChange('dateOfJoining', e.target.value)}
                />
              </FormControl>
              <Button colorScheme="blue" onClick={handleSubmit}>
                {editingStaff ? 'Update Staff Member' : 'Create Staff Member'}
              </Button>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleModalClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default StaffManagement;